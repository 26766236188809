.wrapper-class {
  border: 1px solid lightgrey;
  overflow: auto;
  border-radius: 5px;
  transition: border-color;
  padding: 10px;
}

.wrapper-class:hover {
  border-color: black;
}

.wrapper-class.focused {
  border-width: 2px;
  border-color: black;
}

.wrapper-class.error {
  border-width: 1px;
  border-color: red;
}

.wrapper-class.error.focused {
  border-color: red;
}

.wrapper-class .public-DraftEditor-content {
  font-size: 13px;
}

.wrapper-class .public-DraftEditorPlaceholder-root {
  font-size: 13px;
}

.rdw-link-decorator-wrapper {
  text-decoration: underline;
  cursor: pointer;
}
